import React from "react"

import Layout from "../../../components/Layout/layout"
import SEO from "../../../components/SEO/seo"
import HeroInner from "../../../components/HeroInner/hero-inner"
import ArticleFullWidth from "../../../components/ArticleFullWidth/article-full-width"

const PetSupplies = () => (
  <Layout>
    <SEO title="Pet Supplies" />
    <HeroInner title="Pet Supplies" imageAlt="Pet Supplies" imagePath="services/retail-products/pet-supplies/hero.jpg" />
    
    <ArticleFullWidth
      descriptions={[
        `The category Pets & Animal Supplies presents a broad range of statistics and market data covering the pet industry.`,
        `Blend-Divine for animal supplies offers endless varieties to choose from including, for example, pet food, pet care products, toys for pets, pet furniture, collars, bowls and feeders. Selling pet products has become easier than ever with an increasing variety of retail outlets now selling pet products alongside the traditional pet store. `,
        `We can supply all varieties to the retailers worldwide. Please send your enquiry using the enquiry form and we will send you the details.`,
      ]}
    />
  </Layout>
)

export default PetSupplies
